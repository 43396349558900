import React from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../../layouts/TemplateColumn';
import { PageHeader, PageSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, TextBlock, PageSubSection } from '../../../components';
import GlobalSafetyData, { globalSafetyBlock } from './_global_safety_data'
import { jobCodes } from '../../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `Adverse Reactions for Neoadjuvant Therapy Followed By Adjuvant Therapy for NSCLC`,
    keywords: `keytruda adverse reactions neoadjuvant therapy followed by adjuvant therapy for nsclc`,
    description: `Learn about the immune-mediated adverse reactions for KEYTRUDA® (pembrolizumab) and the most common adverse reactions associated with the KEYNOTE-671 clinical trial.`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/nsclc-neoadjuvant-therapy/","@type":"MedicalWebPage","name":"Most Common Adverse Reactions in KEYNOTE-671","description":"Adverse reactions occurring in patients with resectable NSCLC receiving KEYTRUDA in combination with platinum-containing chemotherapy, given as neoadjuvant treatment and continued as single-agent adjuvant treatment, were generally similar to those occurring in patients in other clinical trials across tumor types receiving KEYTRUDA in combination with chemotherapy."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/nsclc-neoadjuvant-therapy/","@type":"MedicalAudience","audienceType":"health care professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

//from /src/util/data/indications.js
// UPDATE THIS TO THE CORRECT INDICATION #
const indicationId = 34;
const jobCode = jobCodes[4].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections : [
        {
            heading: "Additional Safety Data",
            items: [
                { text: '1L Combo Therapy – Nonsquamous ', url: '/safety/adverse-reactions/nsclc-nonsquamous-first-line-combination-therapy' },
                { text: '1L Combo Therapy – Squamous ', url: '/safety/adverse-reactions/nsclc-squamous-first-line-combination-therapy' },
                { text: '1L Monotherapy – Nonsquamous & Squamous', url: '/safety/adverse-reactions/nsclc-first-line-monotherapy' },
                { text: '2L Monotherapy – Nonsquamous & Squamous', url: '/safety/adverse-reactions/nsclc-second-line-monotherapy' },
                { text: 'Adjuvant Therapy for Stage IB (T2a ≥4 cm), Stage II, or Stage IIIA NSCLC', url: '/safety/adverse-reactions/nsclc-adjuvant-therapy/'}    
            ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Treatment Monitoring & Management', url: '/safety/monitoring-managing-adverse-reactions/' },
                { text: 'Dose Modifications', url: '/dosing/dose-modifications/' },
                ]
        },
    ],
    interestLinks : {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    {label: 'Incidence of Selected Immune-Mediated Adverse Reactions'},
    {label: 'Management of Selected Immune-Mediated Adverse Reactions'},
    {label: 'Most Common Adverse Reactions in KEYNOTE&#8288;-&#8288;671'},

];

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={ pageMeta } location={ location }>
            <div data-template-name="template-e">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Selected Adverse Reactions" />
                   <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId}/>
                   </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title="Severe and Fatal Immune-Mediated Adverse Reactions">
                            <TextBlock>{globalSafetyBlock}</TextBlock>
                            <AnchorLinks items={anchorLinkModelData} />
                        </PageSection>

                        <GlobalSafetyData />

                        <PageSection title={anchorLinkModelData[2].label}>
                            <TextBlock>
                                <i>Neoadjuvant and Adjuvant Treatment of Resectable NSCLC</i>
                                <p>Adverse reactions occurring in patients with resectable NSCLC receiving KEYTRUDA in combination with platinum-containing chemotherapy, given as neoadjuvant treatment and continued as single-agent adjuvant treatment, were generally similar to those occurring in patients in other clinical trials across tumor types receiving KEYTRUDA in combination with chemotherapy.</p>
                            </TextBlock>
                            <PageSubSection title='Neoadjuvant Phase'>
                                <TextBlock>
                                    <p>A total of 396 patients received at least 1 dose of KEYTRUDA in combination with platinum-containing chemotherapy as neoadjuvant treatment, and 399 patients received at least 1 dose of placebo in combination with platinum-containing chemotherapy as neoadjuvant treatment.
                                    </p>
                                    <p>
                                      Serious adverse reactions occurred in 34% of patients who received KEYTRUDA in combination with platinum-containing chemotherapy as neoadjuvant treatment; the most frequent (≥2%) serious adverse reactions were pneumonia (4.8%), venous thromboembolism (3.3%), and anemia (2%). Fatal adverse reactions occurred in 1.3% of patients, including death due to unknown cause (0.8%), sepsis (0.3%), and immune-mediated lung disease (0.3%).
                                    </p>
                                    <p>
                                      Permanent discontinuation of any study drug due to an adverse reaction occurred in 18% of patients who received KEYTRUDA in combination with platinum-containing chemotherapy as neoadjuvant treatment; the most frequent (≥1%) adverse reactions that led to permanent discontinuation of any study drug were acute kidney injury (1.8%), interstitial lung disease (1.8%), anemia (1.5%), neutropenia (1.5%), and pneumonia (1.3%). 
                                    </p>
                                      <p>
                                          Of the 396 KEYTRUDA-treated patients and 399 placebo-treated patients who received neoadjuvant treatment, 6% (n=25) and 4.3% (n=17), respectively, did not receive surgery due to adverse reactions. The most frequent (≥1%) adverse reaction that led to cancellation of surgery in the KEYTRUDA arm was interstitial lung disease (1%).

                                          </p>
                                          <p>
                                          Of the 325 KEYTRUDA-treated patients who received surgery, 3.1% (n=10) experienced delay of surgery (surgery more than 8 weeks from last neoadjuvant treatment if patient received less than 4 cycles of neoadjuvant therapy or more than 20 weeks after first dose of neoadjuvant treatment if patient received 4 cycles of neoadjuvant therapy) due to adverse reactions. Of the 317 placebo-treated patients who received surgery, 2.5% (n=8) experienced delay of surgery due to adverse reactions. 
                                        </p>
                                        <p>
                                          Of the 325 KEYTRUDA-treated patients who received surgery, 7% (n=22) did not receive adjuvant treatment due to adverse reactions. Of the 317 placebo-treated patients who received surgery, 3.2% (n=10) did not receive adjuvant treatment due to adverse reactions.
                                      </p>
                                </TextBlock>
                            </PageSubSection>
                        </PageSection>
                        <PageSection>
                            <PageSubSection title='Adjuvant Phase'>
                                <TextBlock>
                                <p>
                                  A total of 290 patients in the KEYTRUDA arm and 267 patients in the placebo arm received at least 1 dose of adjuvant treatment.
                               </p>

                                <p>
                                  Of the patients who received single agent KEYTRUDA as adjuvant treatment, 14% experienced serious adverse reactions; the most frequent serious adverse reaction was pneumonia (3.4%). One fatal adverse reaction of pulmonary hemorrhage occurred. Permanent discontinuation of adjuvant KEYTRUDA due to an adverse reaction occurred in 12% of patients; the most frequent (≥1%) adverse reactions that led to permanent discontinuation of adjuvant KEYTRUDA were diarrhea (1.7%), interstitial lung disease (1.4%), aspartate aminotransferase increased (1%), and musculoskeletal pain (1%).
                               </p>
                                </TextBlock>
                            </PageSubSection>
                        </PageSection>
                    </TemplateColumn>
                    {/* End Main Column */}
                    
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent}/>
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
